<template>
  <div class="photoStart">
    <LoadingPlaceholder v-if="preparing == true" />
    <template v-if="photos && photos.spotOne && photos.spotOne.length > 0 && photos.spotTwo && photos.spotTwo.length > 0">
      <div
        id="nav-tab"
        class="nav nav-tabs"
        role="tablist"
      >
        <a
          id="spoTOne-tab"
          class="nav-item nav-link active"
          data-toggle="tab"
          href="#spoTOne"
          role="tab"
          aria-controls="spoTOne"
          aria-selected="true"
        >Spot: {{ photos.spotOne[0].spotId }}</a>
        <a
          id="spotTwo-tab"
          class="nav-item nav-link"
          data-toggle="tab"
          href="#spotTwo"
          role="tab"
          aria-controls="spotTwo"
          aria-selected="false"
        >Spot: {{ photos.spotTwo[0].spotId }}</a>
      </div>
      <div
        id="nav-tabContent"
        class="tab-content"
      >
        <div
          id="spoTOne"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="spoTOne-tab"
        >
          <SessionOverview
            :runs="photos.spotOne"
            :display-properties="['createDate', 'card']"
            :loading="loading"
            :filter="filter"
            :installation-type="installationType"
            :installation-id="installationId"
            @loadSessions="loadSessions"
          />
        </div>
        <div
          id="spotTwo"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="spotTwo-tab"
        >
          <SessionOverview
            :runs="photos.spotTwo"
            :display-properties="['createDate', 'card']"
            :loading="loading"
            :filter="filter"
            :installation-type="installationType"
            :installation-id="installationId"
            @loadSessions="loadSessions"
          />
        </div>
      </div>
    </template>

    <SessionOverview
      v-else-if="photos && photos.spotOne && photos.spotOne.length > 0"
      :display-properties="['createDate', 'card']"
      :runs="photos.spotOne"
      :installation-type="installationType"
      :loading="loading"
      :filter="filter"
      :installation-id="installationId"
      @loadSessions="loadSessions"
    />

    <SessionOverview
      v-else-if="photos && photos.spotTwo && photos.spotTwo.length > 0"
      :display-properties="['createDate', 'card']"
      :runs="photos.spotTwo"
      :installation-type="installationType"
      :loading="loading"
      :filter="filter"
      :installation-id="installationId"
      @loadSessions="loadSessions"
    />

    <p v-else-if="preparing == false && (photos == null || (photos.spotOne.length == 0 && photos.spotTwo.length == 0))">
      No data available.
    </p>

    <SweetModal
      :id="modalId"
      ref="photoStartmodal"
      title="Session"
      width="75%"
      @closeModal="closeModal"
    >
      <template
        slot="box-action"
      >
        <toggle-button
          v-model="viewDecoratedPhotos"
          :labels="{ checked: 'decorated', unchecked: 'normal' }"
          :width="100"
          :height="30"
          :font-size="12"
          class="mr-3 mt-2"
        />
      </template>
      <sweet-modal-tab
        id="tab1"
        title="Photo"
      >
        <img
          v-if="altImageAvailable"
          id="img"
          :src="altImageUrl"
          class="img-fluid"
        >
        <ImagePlaceholderSvg
          v-else
          class="img-fluid"
        />
      </sweet-modal-tab>

      <sweet-modal-tab
        id="tab2"
        title="Details"
      >
        <table
          v-if="payload != null"
          class="defaultTable"
        >
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>Id</th>
              <td class="lineBreaks">
                {{ payload.id }}
              </td>
            </tr>
            <tr>
              <th>Card Number</th>
              <td class="lineBreaks">
                {{ payload.identificationId }}
              </td>
            </tr>
            <tr>
              <th>Create date</th>
              <td v-tooltip="dateTime_fromNow(payload.created)">
                {{ dateTime_dateTime(payload.created) }}
              </td>
            </tr>
            <tr>
              <th>Image Url</th>
              <td>{{ payload.imageUrl }}</td>
            </tr>
            <tr>
              <th>Snapshot Url</th>
              <td>{{ payload.snapshotUrl }}</td>
            </tr>
            <tr>
              <th>Spot Id</th>
              <td>{{ payload.spotId }}</td>
            </tr>
          </tbody>
        </table>
      </sweet-modal-tab>
      <sweet-modal-tab
        id="tab3"
        title="EXIF"
      >
        <pre v-if="exifData">{{ exifData }}</pre>
        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>
      </sweet-modal-tab>
    </SweetModal>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
  name: 'PhotoStart',
  components: {
    ImagePlaceholderSvg: () => import('@/components/Base/ImagePlaceholderSvg.vue'),
    SessionOverview: () => import('@/components/Media/SessionOverview.vue'),
    SweetModal,
    SweetModalTab
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    installationType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      useAlternativeFilter: true,
      loading: true,
      photos: null,
      altImageUrl: "",
      modalId: 'photostartModal',
      filter: {
        dates: null,
        cardNumber: null,
        startDate: null,
        endDate: null,
        showIncomplete: true,
        limit: 100
      },
      preparing: true,
      payload: null,
      viewDecoratedPhotos: true,
      exifData: null
    }
  },
  computed: {
    areTherePhotos: function () {
      if (this.photos && this.photos.length > 0) return true;
      return false;
    },
    altImageAvailable: function () {
      if (this.payload && this.altImageUrl && this.altImageUrl.length > 0) {
        return true;
      }
      return false;
    } 
  },
  watch: {
    viewDecoratedPhotos (val) {
      if(this.altImageAvailable) {
        this.altImageUrl = val ? this.decoratedUrl(this.payload.id) : this.payload.imageUrl;
      }
    }
  },
  created () {
    let tmpDate = new Date(Date.now());
    this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
    this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
    this.filter.dates = [];
    this.filter.dates.push(this.filter.startDate, this.filter.endDate);

    this.loadSessions();
    this.$eventBus.$on(`openModal${this.modalId}`, this.openModal);
  },
  beforeDestroy () {
    this.$eventBus.$off(`openModal${this.modalId}`, this.openModal);
  },
  methods: {
    decoratedUrl (runId) {
      return "https://api.skiline.cc/php/app/api-helper/0.10/Admin/Video/decoratedRun.php?runId="+runId+"&apiKey=383zhdd7yJHDOcvmiq563wnmdjuhJ83wdsn";
    },
     openModal (payload) {
      if (payload) {
        this.getExifData(payload);
        this.payload = payload;
        if(this.viewDecoratedPhotos) {
          this.altImageUrl = this.decoratedUrl(payload.id);
        } 
        else {
          this.altImageUrl = payload.imageUrl;
        }
      }
      this.$refs.photoStartmodal.open();
    },
    closeModal () {
      this.payload = null;
      this.altImageUrl = '';
      this.exifData = null;
      this.$refs.photoStartmodal.close();
    },
    // GET DEVICE gets the data for the device specified by the deviceId
    loadSessions (payload) {
      if (payload == null || this.useAlternativeFilter == true) {
        payload = {
          limit: 100,
          dates: [this.useAlternativeFilter == true ? null : this.filter.startDate, this.useAlternativeFilter == true ? null : this.filter.endDate]
        }
      }
      this.loading = true;
      this.axios.get(`/Media/GetPhotostart/${ this.installationId }/${ payload.limit }/${ this.dateTime_isoString(payload.dates[0]) }/${ this.dateTime_isoString(payload.dates[1]) }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.length === 0) {
            return;
          }
          let spotIdOne = response.data[0].spotId;
          let runs = {
            spotOne: [],
            spotTwo: []
          };
          response.data.forEach(element => {
            if (element.spotId == spotIdOne) {
              runs.spotOne.push(element);
            } else {
              runs.spotTwo.push(element);
            }
          });
          this.photos = runs;
        })
        .finally(() => {
          this.loading = false;
          window.setTimeout(() => {
            this.preparing = false;
          }, 500);
        });
    },
    getExifData (payload) {
      if(payload.imageUrl) {
        var urlObj = {
          url: payload.imageUrl
        };

        this.axios.post(`/Media/GetExifData`, urlObj)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.exifData = JSON.stringify(JSON.parse(response.data),null,2);
        });
      }
    }
  }
}
</script>